html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  border: 0;
  margin: 0;
  padding: 0;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

@font-face {
  font-family: InterVariable;
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url("InterVariable.e6ec8b96.woff2") format("woff2");
}

@font-face {
  font-family: InterVariable;
  font-style: italic;
  font-weight: 100 900;
  font-display: swap;
  src: url("InterVariable-Italic.e0a5e81d.woff2") format("woff2");
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url("Inter-Thin.229ba378.woff2") format("woff2");
}

@font-face {
  font-family: Inter;
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url("Inter-ThinItalic.9f81ab2f.woff2") format("woff2");
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url("Inter-ExtraLight.a7c091c3.woff2") format("woff2");
}

@font-face {
  font-family: Inter;
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url("Inter-ExtraLightItalic.715e9b66.woff2") format("woff2");
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("Inter-Light.d257580c.woff2") format("woff2");
}

@font-face {
  font-family: Inter;
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url("Inter-LightItalic.fc7aee7e.woff2") format("woff2");
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("Inter-Regular.c3474174.woff2") format("woff2");
}

@font-face {
  font-family: Inter;
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url("Inter-Italic.f23ba37f.woff2") format("woff2");
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("Inter-Medium.275a3474.woff2") format("woff2");
}

@font-face {
  font-family: Inter;
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url("Inter-MediumItalic.a2ebbdce.woff2") format("woff2");
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("Inter-SemiBold.0cbc243f.woff2") format("woff2");
}

@font-face {
  font-family: Inter;
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url("Inter-SemiBoldItalic.eedb80b8.woff2") format("woff2");
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("Inter-Bold.ff33b6b2.woff2") format("woff2");
}

@font-face {
  font-family: Inter;
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url("Inter-BoldItalic.67e04f74.woff2") format("woff2");
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url("Inter-ExtraBold.7783db4d.woff2") format("woff2");
}

@font-face {
  font-family: Inter;
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: url("Inter-ExtraBoldItalic.449f9604.woff2") format("woff2");
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url("Inter-Black.f108228e.woff2") format("woff2");
}

@font-face {
  font-family: Inter;
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url("Inter-BlackItalic.be778dd7.woff2") format("woff2");
}

@font-face {
  font-family: InterDisplay;
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url("InterDisplay-Thin.c3f516cc.woff2") format("woff2");
}

@font-face {
  font-family: InterDisplay;
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url("InterDisplay-ThinItalic.f3901fc6.woff2") format("woff2");
}

@font-face {
  font-family: InterDisplay;
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url("InterDisplay-ExtraLight.76c6ada4.woff2") format("woff2");
}

@font-face {
  font-family: InterDisplay;
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url("InterDisplay-ExtraLightItalic.b7d1cc8e.woff2") format("woff2");
}

@font-face {
  font-family: InterDisplay;
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("InterDisplay-Light.ea1ed5c1.woff2") format("woff2");
}

@font-face {
  font-family: InterDisplay;
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url("InterDisplay-LightItalic.a16ddbe0.woff2") format("woff2");
}

@font-face {
  font-family: InterDisplay;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("InterDisplay-Regular.c8669ab4.woff2") format("woff2");
}

@font-face {
  font-family: InterDisplay;
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url("InterDisplay-Italic.b84a6a9e.woff2") format("woff2");
}

@font-face {
  font-family: InterDisplay;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("InterDisplay-Medium.15a40551.woff2") format("woff2");
}

@font-face {
  font-family: InterDisplay;
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url("InterDisplay-MediumItalic.88fcd567.woff2") format("woff2");
}

@font-face {
  font-family: InterDisplay;
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("InterDisplay-SemiBold.ece3e909.woff2") format("woff2");
}

@font-face {
  font-family: InterDisplay;
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url("InterDisplay-SemiBoldItalic.3faac207.woff2") format("woff2");
}

@font-face {
  font-family: InterDisplay;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("InterDisplay-Bold.65222797.woff2") format("woff2");
}

@font-face {
  font-family: InterDisplay;
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url("InterDisplay-BoldItalic.55565bcf.woff2") format("woff2");
}

@font-face {
  font-family: InterDisplay;
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url("InterDisplay-ExtraBold.01c858ec.woff2") format("woff2");
}

@font-face {
  font-family: InterDisplay;
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: url("InterDisplay-ExtraBoldItalic.2c79cfee.woff2") format("woff2");
}

@font-face {
  font-family: InterDisplay;
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url("InterDisplay-Black.e6f2d5cc.woff2") format("woff2");
}

@font-face {
  font-family: InterDisplay;
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url("InterDisplay-BlackItalic.5dc0d872.woff2") format("woff2");
}

:root {
  font-family: Inter, sans-serif;
}

@supports (font-variation-settings: normal) {
  :root {
    font-optical-sizing: auto;
    font-family: InterVariable, sans-serif;
  }
}

html {
  scroll-behavior: smooth;
}

.figurative {
  -webkit-font-smoothing: antialiased;
  color: #fff;
  background: #0c0c0c;
  font-size: 22px;
  line-height: 1.2;
}

.figurative .content {
  max-width: 1000px;
  margin: 0 auto;
  padding-left: 40px;
  padding-right: 40px;
}

.figurative .no-break {
  white-space: nowrap;
}

.figurative main > header {
  padding-top: 60px;
  padding-bottom: 60px;
}

.figurative main > header ul li {
  display: inline-block;
}

.figurative main > header ul li:not(:last-child) {
  margin-right: 32px;
}

@media (width <= 750px) {
  .figurative main > header ul li {
    margin-bottom: .25em;
    display: block;
  }
}

.figurative main > header a {
  color: gray;
  font-weight: 400;
  text-decoration: none;
  transition: color .25s ease-out;
}

.figurative main > header a:hover {
  color: #fff;
  transition-duration: 0s;
}

.figurative main > header .current a {
  color: #fff;
}

.figurative main > header .logo {
  font-weight: 600;
}

.figurative .apps {
  margin-bottom: 2em;
}

.figurative .apps header {
  text-align: center;
  padding: 0 2em;
}

.figurative .apps header h1 {
  font-size: 34px;
  font-weight: 600;
}

@media (width <= 750px) {
  .figurative .apps header h1 {
    font-size: 18px;
  }
}

.figurative .apps header .icon {
  margin-bottom: 1em;
}

.figurative .apps header .featurette {
  color: #ffb800;
  font-style: italic;
}

.figurative .apps header:after {
  content: "";
  border-right: 3px dotted gray;
  width: 0;
  height: 4em;
  margin: 2em auto 0;
  display: block;
}

@media (width <= 750px) {
  .figurative .apps header:after {
    height: 1em;
    margin-top: 1em;
  }
}

.figurative .apps section:nth-child(2) header:after {
  height: 8em;
}

@media (width <= 750px) {
  .figurative .apps section:nth-child(2) header:after {
    height: 4em;
  }

  .figurative .apps section:first-child header {
    padding-left: 0;
  }

  .figurative .apps section:nth-child(2) header {
    padding-right: 0;
  }
}

.figurative .apps .tile {
  filter: drop-shadow(0 4px 32px #0003);
  position: relative;
}

.figurative .apps .tile .screenshot {
  background: #1b1b1b;
  outline: none;
  width: 100%;
  margin: 0;
  padding: 0;
  font-size: 0;
  display: block;
  position: relative;
  -webkit-mask-image: url("tile-mask.244124ba.svg");
  mask-image: url("tile-mask.244124ba.svg");
  -webkit-mask-size: contain;
  mask-size: contain;
}

.figurative .apps .tile .screenshot img {
  width: 100%;
  max-width: 100%;
  height: auto;
}

.figurative .apps .description {
  padding: 2em;
}

@media (width <= 750px) {
  .figurative .apps .description {
    display: none;
  }
}

.figurative .apps .stacked-descriptions {
  text-align: center;
  margin-top: 1em;
  padding: 2em 0;
  font-size: 17px;
  display: none;
}

@media (width <= 750px) {
  .figurative .apps .stacked-descriptions {
    display: block;
  }
}

.figurative .apps .stacked-descriptions > :not(:last-child) {
  margin-bottom: 2em;
}

.figurative .apps .cards {
  --inter-spacing: -34px;
  justify-content: center;
  align-items: stretch;
  display: flex;
}

.figurative .apps .cards > * {
  box-sizing: border-box;
  flex: 1;
}

.figurative .apps .cards > :first-child {
  margin-right: var(--inter-spacing);
}

.figurative .apps .cards > :first-child .description {
  padding-right: calc(1em + var(--inter-spacing) * -2);
}

.figurative .apps .cards > :nth-child(2) {
  margin-left: var(--inter-spacing);
}

.figurative .apps h2 {
  color: gray;
  margin-bottom: 1em;
}

.figurative .get {
  background: #1b1b1b;
  padding-top: 2em;
  padding-bottom: 2em;
}

.figurative .get .panes {
  --inter-spacing: 13px;
  justify-content: center;
  align-items: stretch;
  padding: 2em;
  display: flex;
}

@media (width <= 750px) {
  .figurative .get .panes {
    padding: 0;
    display: block;
  }
}

@media (width >= 750px) {
  .figurative .get .panes > * {
    box-sizing: border-box;
    flex: 1;
  }

  .figurative .get .panes > :first-child {
    margin-right: var(--inter-spacing);
  }

  .figurative .get .panes > :nth-child(2) {
    margin-left: var(--inter-spacing);
  }
}

.figurative .get .latest h1 {
  margin-bottom: .25em;
  font-weight: 600;
}

.figurative .get .latest h2 {
  color: gray;
}

.figurative .get .latest .app-store {
  cursor: pointer;
  transition: transform .5s ease-out;
  display: inline-block;
}

.figurative .get .latest .app-store:hover {
  transition-duration: .15s;
  transform: scale(1.05);
}

.figurative .get .latest * + * {
  margin-bottom: 1em;
}

.figurative .get .latest .links {
  position: relative;
}

.figurative .get .latest .links .disabled {
  pointer-events: none;
}

.figurative .get .latest .links figure {
  margin-top: -44px;
}

.figurative .get .latest .links figure img {
  pointer-events: none;
  transform-origin: 50% 4px;
  animation: 2s ease-in-out infinite alternate swing;
}

@keyframes swing {
  0% {
    transform: rotate(-5deg);
  }

  100% {
    transform: rotate(5deg);
  }
}

.figurative .get .menu h3 {
  color: gray;
  margin-bottom: 1em;
}

.figurative .get .menu a {
  color: #fff;
  text-decoration: none;
}

.figurative .get .menu .links {
  display: table;
}

.figurative .get .menu .links a {
  width: 100%;
  transition: color .25s ease-out;
  display: inline-block;
}

.figurative .get .menu .links:hover a {
  color: gray;
}

.figurative .get .menu .links:hover a:hover {
  color: #fff;
  transition-duration: 0s;
}

@media (width <= 750px) {
  .figurative .get .menu {
    font-size: 17px;
  }
}

.figurative main > footer {
  color: #646464;
  padding: 4em;
  font-size: 17px;
}

@media (width <= 750px) {
  .figurative main > footer {
    padding-left: 0;
    padding-right: 0;
  }
}

.figurative main > footer a {
  color: inherit;
}

.figurative main > footer p {
  margin-bottom: 1em;
}

article {
  max-width: 1000px;
  margin: 0 auto;
  padding-left: 40px;
  padding-right: 40px;
  font-size: 17px;
  line-height: 1.4;
}

article h1 {
  margin-bottom: 1em;
  font-size: 34px;
  font-weight: 600;
}

article h2 {
  font-size: 24px;
  font-weight: 600;
}

article h3 {
  font-size: 24px;
  font-weight: 500;
}

article strong, article b {
  font-weight: 600;
}

article em, article i {
  font-style: italic;
}

article blockquote {
  border-left: 4px solid #ffb800;
  padding-left: 1em;
}

article a {
  color: #ffb800;
}

article * + * {
  margin-bottom: 1em;
}

/*# sourceMappingURL=index.c0691f9f.css.map */
