@import "reset.css";
@import "fonts/inter.css";

// Fonts:

:root { font-family: "Inter", sans-serif; }

@supports (font-variation-settings: normal) {
  :root { font-family: "InterVariable", sans-serif; font-optical-sizing: auto; }
}

// Scrolling:

html {
  scroll-behavior: smooth;
}

// Style:

.figurative {
  -webkit-font-smoothing: antialiased;
  background: #0C0C0C;
  color: #FFF;
  
  font-size: 22px;
  line-height: 1.2;
  
  // 
  
  .content {
    max-width: 1000px;
    margin: 0 auto;
    
    padding-right: 40px;
    padding-left: 40px;
  }
  
  .no-break {
    white-space: nowrap;
  }
  
  // Header:
  
  main > header {
    padding-top: 60px;
    padding-bottom: 60px;
    
    ul li {
      display: inline-block;
      
      &:not(:last-child) {
        margin-right: 32px;
      }
      
      @media (max-width: 750px) {
        display: block;
        margin-bottom: 0.25em;
      }
    }
    
    a {
      color: #808080;
      text-decoration: none;
      font-weight: 400;
      
      transition: color 0.25s ease-out;
      
      &:hover {
        transition-duration: 0s;
        color: white;
      }
    }
    
    .current a {
      color: #FFF;
    }
    
    .logo {
      font-weight: 600;
    }
    
  }
  
  // Apps:
  
  .apps {
    margin-bottom: 2em;
    
    header {
      padding: 0 2em;
      text-align: center;
      
      h1 {
        font-size: 34px;
        font-weight: 600;
        
        @media (max-width: 750px) {
          font-size: 18px;
        }
      }
      
      .icon {
        margin-bottom: 1em;
      }
      
      .featurette {
        font-style: italic;
        color: #FFB800;
      }
      
      &:after {
        content: "";
        display: block;
        margin: 0 auto;
        margin-top: 2em;
        
        border-right: 3px dotted gray;
        width: 0px;
        height: 4em;
        
        @media (max-width: 750px) {
          margin-top: 1em;
          height: 1em;
        }
      }
    }
    
    section:nth-child(2) header:after {
      height: 8em;
      
      @media (max-width: 750px) {
        height: 4em;
      }
    }
    
    @media (max-width: 750px) {
      
      section:nth-child(1) header {
        padding-left: 0;
      }
      
      section:nth-child(2) header {
        padding-right: 0;
      }
      
    }
    
    .tile {
      position: relative;
      filter: drop-shadow(0px 4px 32px rgba(0, 0, 0, 0.2));
      
      .screenshot {
        position: relative;
        
        display: block;
        margin: 0;
        padding: 0;
        outline: none;
        font-size: 0;
        
        width: 100%;
        background: #1B1B1B;
        
        mask-size: contain;
        mask-image: url(img/tile-mask.svg);
        
        img {
          max-width: 100%;
          width: 100%;
          height: auto;
        }
      }
    }
    
    .description {
      padding: 2em;
      
      @media (max-width: 750px) {
        display: none;
      }
    }
    
    .stacked-descriptions {
      margin-top: 1em;
      
      text-align: center;
      padding: 2em 0;
      
      display: none;
      font-size: 17px;
      
      @media (max-width: 750px) {
        display: block;
      }
      
      & > *:not(:last-child) {
        margin-bottom: 2em;
      }
    }
    
    .cards {
      --inter-spacing: -34px;
      
      display: flex;
      justify-content: center;
      align-items: stretch;
      
      & > * {
        flex: 1;
        box-sizing: border-box;
        
        &:nth-child(1) {
          margin-right: var(--inter-spacing);
          
          .description {
            padding-right: calc(1em + var(--inter-spacing) * -2);
          }
        }
        
        &:nth-child(2) {
          margin-left: var(--inter-spacing);
        }
      }
    }
    
    h2 {
      color: #808080;
      margin-bottom: 1em;
    }
  }
  
  // Get:
  
  .get {
    background: #1B1B1B;
    
    padding-top: 2em;
    padding-bottom: 2em;
    
    .panes {
      padding: 2em;
      
      --inter-spacing: 13px;
      
      display: flex;
      justify-content: center;
      align-items: stretch;
      
      @media (max-width: 750px) {
        display: block;
        padding: 0;
      }
      
      @media (min-width: 750px) {
        & > * {
          flex: 1;
          box-sizing: border-box;
          
          &:nth-child(1) {
            margin-right: var(--inter-spacing);
          }
          
          &:nth-child(2) {
            margin-left: var(--inter-spacing);
          }
        }
      }
      
      
    }
    
    .latest {
      h1 {
        font-weight: 600;
        margin-bottom: 0.25em;
      }
      
      h2 {
        color: #808080;
      }
      
      .app-store {
        display: inline-block;
        cursor: pointer;
        transition: transform 0.5s ease-out;
        
        &:hover {
          transition-duration: 0.15s;
          transform: scale(1.05);
        }
      }
      
      * + * {
        margin-bottom: 1em;
      }
      
      .links {
        position: relative;
        
        .disabled {
          pointer-events: none;
        }
        
        figure {
          margin-top: -44px;
          
          img {
            pointer-events: none;
            
            transform-origin: center 4px;
            
            animation: 2s swing ease-in-out;
            animation-iteration-count: infinite;
            animation-direction: alternate;
          }
          
          @keyframes swing {
            
            0% {
              transform: rotate(-5deg);
            }
            
            100% {
              transform: rotate(5deg);
            }
          }
        }
      }
    }
    
    .menu {
      h3 {
        color: #808080;
        margin-bottom: 1em;
      }
      
      a {
        color: #FFF;
        text-decoration: none;
      }
      
      .links {
        display: table;
        
        a {
          display: inline-block;
          width: 100%;
          
          transition: color 0.25s ease-out;
        }
        
        &:hover a {
          color: #808080;
          
          &:hover {
            color: white;
            transition-duration: 0s;
          }
        }
      }
      
      @media (max-width: 750px) {
        font-size: 17px;
      }
    }
  }
  
  
  // Footer:
  
  main > footer {
    font-size: 17px;
    
    padding: 4em;
    
    color: #646464;
    
    @media (max-width: 750px) {
      padding-left: 0;
      padding-right: 0;
    }
    
    a {
      color: inherit;
    }
    
    p {
      margin-bottom: 1em;
    }
    
  }
}

// Articles:

article {
  max-width: 1000px;
  margin: 0 auto;
  
  padding-right: 40px;
  padding-left: 40px;
  
  font-size: 17px;
  line-height: 1.4;
  
  h1 {
    font-size: 34px;
    font-weight: 600;
    
    margin-bottom: 1em;
  }
  
  h2 {
    font-size: 24px;
    font-weight: 600;
  }
  
  h3 {
    font-size: 24px;
    font-weight: 500;
  }
  
  strong, b {
    font-weight: 600;
  }
  
  em, i {
    font-style: italic;
  }
  
  blockquote {
    padding-left: 1em;
    border-left: 4px solid #FFB800;
  }
  
  a {
    color: #FFB800;
  }
  
  * + * {
    margin-bottom: 1em;
  }
}
